
.container {
    overflow-y: auto;
    padding: 5px;
    min-width: 100px;
}

.pdfContainerOcr {
    width: 100%;
    height: 100%;
    border-radius: 4px;
    padding: 10px;
    box-sizing: border-box;
    overflow-y: auto;
    flex-wrap: wrap;
    position: relative;
    /*display: flex;*/
    transform-origin:0% 0%;
    overflow-x: hidden;
    justify-content: center;
    background: #2A2A2E;
}


.selectedPage {
    border: 3px solid #007bff !important;
    box-shadow: 0px 4px 12px rgba(0, 123, 255, 0.3) !important;
    border-radius: 8px;
    transition: all 0.3s ease-in-out;
}

.container::-webkit-scrollbar {
    width: 10px;
    background: #f2f2f2;
    border-right: 1px solid #D5D5D5;
    border-left: 1px solid #D5D5D5;
    margin: 2px;
}

.container::-webkit-scrollbar-thumb {
    color: #D5D5D5;
    background: #D5D5D5;
    border-radius: 3px;
    background-clip: padding-box;
    border: 2px solid transparent;
}

.pageWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.canvasPointer {
    cursor: pointer;
}