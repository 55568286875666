
.container {
    width: 100%;
    border-radius: 4px;
    background: #EFEFEF;
    padding: 20px 0 0 20px;
    box-sizing: border-box;
    overflow-y: auto;
    flex-wrap: wrap;
    position: relative;
    display: flex;
    transform-origin:0% 0%;
    overflow-x: hidden;
}

.container canvas {
    margin-right: 20px;
    margin-bottom: 20px;
}

.pdfContainerOcr {
    width: 100%;
    height: 100%;
    border-radius: 4px;
    padding: 10px;
    box-sizing: border-box;
    overflow-y: auto;
    flex-wrap: wrap;
    position: relative;
    /*display: flex;*/
    transform-origin:0% 0%;
    overflow-x: hidden;
    justify-content: center;
    background: #2A2A2E;
}