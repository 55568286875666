.modalOverlay {
    display: none;
    position:fixed;
    padding:0;
    margin:0;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    background: rgba(0,0,0,0.5);
}

.alertBox {
    display: flex;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
    /*height: calc(100% - 100px);*/
    /*padding: 50px 0;*/
}

.modal {
    border: 1px solid #D5D5D5;
    border-radius: 4px;
    box-shadow: 0 3px 6px #00000010;
    background: #FFFFFF;
    position: fixed;
    color: #555555;
    text-align: center;
    width: 75%;
    top: 25px;
    bottom: 25px;
    left: 12.5%;
    right: 12.5%;
    overflow: auto;
}


.modalContainer {
    flex: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    background: #F4F4F4;
}

.modalBody {
    height: 100%;
    display: flex;
    overflow-y: hidden;
    overflow-x: auto;
    background: #EFEFEF;
    justify-content: space-between;
}

.pdfFileContainer {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    margin: 10px 0 10px 10px;
}

.ocrContainer {
    flex: 1;
    display: flex;
    flex-direction: column;
    background: #fff;
    position: relative;
    margin: 10px;
    border: var(--defaultBorder);
    border-radius: 4px;
}

.ocrContainerBody {
    display: flex;
    flex-direction: column;
    gap: 2px;
    padding: 10px 13px 13px 13px;
    overflow-x: auto;
}

.ocrItem {
    display: flex;
    text-align: left;
}

h3 {
    text-align: left;
    margin-bottom: 10px;
}

.modalFooter {
    background: #F4F4F4;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    border-top: var(--defaultBorder);
    padding: 5px 10px;
}

.copyButtonWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 17px 5px 20px;
    background: #e7e7e7;
}

.copyButtonWrapper img {
    width: 20px;
    height: 20px;
    cursor: pointer;
}

.loadingContainer {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
}

.imagesContainerSection {
    height: 100%;
    max-width: 100px;
}
