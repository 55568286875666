
/*modal*/

.modalContainer {
    position:fixed;
    padding:0;
    margin:0;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    background: rgba(0,0,0,0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}


.modal {
    width: 80%;
    height: 80%;
    border-radius: 4px;
    border: var(--defaultBorder);
    /*margin: auto auto;*/
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    position: fixed;
    left: 0;
    right: 0;
    background: #fff;
    margin: auto;
    /*margin-top: -100px; !* Negative half of height. *!*/
    /*margin-left: -250px; !* Negative half of width. *!*/
}

.modal-body {
    overflow: hidden;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.modal-list {
    max-width: 100%;
    width: 100%;
    height: 100%;
}

.modal-column-1 {
    text-align: center !important;
    width: 36px !important;
    min-width: 36px !important;
}

.modal-column-2 {
    width: 130px !important;
    min-width: 130px !important;
}

.modal-column-3 {
    width: 150px !important;
    min-width: 150px !important;
}

.modal-column-4 {
    width: 150px !important;
    min-width: 150px !important;
}

.modal-column-5 {
    width: 150px !important;
    min-width: 150px !important;
}

.modal-column-6 {
    width: 150px !important;
    min-width: 150px !important;
}

.modal-column-7 {
    width: 150px !important;
    min-width: 150px !important;
}

.modal-column-8 {
    width: 150px !important;
    min-width: 150px !important;
}

.modal-column-9 {
    width: 150px !important;
    min-width: 150px !important;
}

.modal-column-10 {
    width: 150px !important;
    min-width: 150px !important;
}
.modal-column-11 {
    width: 150px !important;
    min-width: 150px !important;
}
.modal-column-12 {
    width: 12px !important;
    min-width: 12px !important;
}


.modal footer {
    padding: 10px;
    display: flex;
    justify-content: space-between;
    border-top: var(--defaultBorder);
    margin-top: auto;
}

.modalListText {
    color: #333333;
    font-size: 12px;
    border-bottom: 1px solid #D5D5D5;
    height: 45px;
    min-height: 45px;
    line-height: 17px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    overflow-x: scroll;
    scroll-behavior: smooth;
}

.modalListText::-webkit-scrollbar {
    display: none;
  }
  
.itemCode {
width: fit-content;
}

.labelTypeContainer {
    margin-top: 15px;
    margin-left: 15px;
    font-size: 16px;
}

.numberBox {
    border: 2px dashed #ccc;
    text-align: center;
    width: 50px;
    height: 25px;
    border-radius: 5px;
}

.gridContainer {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 5px;
    max-width: 120px;
    margin-top: 5px;
    padding: 7px;
    border: 1px solid rgba(230, 230, 230, 0.8);
    box-shadow: -5px 0 5px rgba(0, 0, 0, 0.02), 5px 0 5px rgba(0, 0, 0, 0.02), 0 5px 5px rgba(0, 0, 0, 0.02);
}

.labelTypeRadioContainer {
    display: flex;
}

.labelTypeRadioContainer > .radioContainer {
    width: 450px;
    font-size: 16px;
    min-height: 183px;
    color: #333333;
}